@use '../../styles/abstracts' as *;

footer {
    background: $color-primary-dark;

}

.container {
    grid-template-rows: 30vh 30vh;
    @include mq(medium){
        grid-template-rows: 35vh;
    }

    @include mq(large){
        grid-template-rows: 50vh;
    }
}

.get_in_touch {
    @include block($color-primary-light, $color-primary-dark);
    @include flex-center;
    text-transform: uppercase;
    color: $color-primary-dark;

    p {
        font-weight: 500;
    }
}

.contact { 
    position: relative;
    @include block($color-highlight-orange, $color-primary-light);
    @include flex-center;

    @include mq(large){
        margin-bottom: unset;
    }
    a {
        color: $color-primary-light;
    }
}

.social_media {
    width: 100%;
    position: absolute;
    bottom: 0;
    margin: .5rem;
    @include flex(center, space-between);
    color: $color-primary-light;

    p {
        margin-left: 2rem;

        @include mq(medium) {
            margin-left: 4rem;
        }
    }

    &_icon {
        display: flex;
        margin-right: 2rem;
        svg {
            width: 1.5rem;
            fill: $color-primary-light;
            padding-left: 1rem;

            @include mq(medium) {
                padding-left: 1.5rem;
            }

            @include mq(large) {
                width: 1.8rem;
            }
        }
        
        @include mq(medium) {
            margin-right: 4rem;
        }
    }
}