@use '../../../styles/abstracts' as *;


.section {
    @include grid;
    grid-template-rows: 30vh 70vh 2fr 0.8fr 0.8fr;
    @include mq(xsmall) {
        grid-template-rows: 40vh 60vh 2fr 1.1fr 1.1fr;
    }

    @include mq(small) {
        grid-template-rows: 40vh 60vh 2.3fr 1.3fr 1.3fr;
    }

    @include mq(medium) {
        grid-template-rows: 50vh 50vh 2fr 1.3fr;
    }

    @include mq(large) {
        grid-template-rows: repeat(3, 1fr);
        height: 100vh;
    }
}


.introduction, .hobby {
    @include block($color-secondary-white, $color-primary-dark);
    @include flex-center;
    padding: 2rem;
}

.introduction {
    @include gridCol(1, 12);
   
    @include mq(large) {
        height: 100%;
        padding: unset;
        flex-direction: unset;
        justify-content: center;
        grid-area: 2 / 1 / span 1 / span 6;
        align-self: end;
    }
}

.title {
    @include gridCol(1, 12);
    align-self: flex-end;

    @include mq(large) {
        grid-area: 1 / 1 / span 1 / span 3;
        align-self: end;
    }
}

.profile {
    @include grid;

    @include mq(medium) {
        flex-direction: unset;
        justify-content: space-evenly;
        align-items: center;
    }

    &_content {
        @include gridCol(1, 12);
        justify-self: center;
        @include mq(medium) {
            @include gridCol(7, 6);
        }

        @include mq(large) {
            @include gridCol(6, 6);
        }
    }
 
    img {
        @include gridCol(1, 9);
        max-width: 100%;
        border-radius: 4rem;
        margin-bottom: 1rem;
        max-width: 16rem;
        justify-self: center;
        @include mq(small) {
            @include gridCol(1, 6);
        }

        @include mq(medium) {
            @include gridCol(1, 5);
        }

        @include mq(large) {
            max-width: 11rem;
        }

    }

}


.h2 {
    font-weight: 600;
    margin-bottom: .5rem;
}

.more_about_me {
    @include block($color-primary-dark, $color-primary-light);
    @include flex-center;
    @include gridCol(1, 12);

    @include mq(large) {
        height: 100%;
        grid-row: 2 / span 2;
        align-self: self-end;
        @include gridCol(auto, 6);
    }

    &_content {
        @include block-padding;

        @include mq(large) {
            padding: 6rem;
        }
       
    }
}

.hobby {
   @include mq(small) {
    @include gridCol(1, 12);
   }

   @include mq(medium) {
    @include gridCol(1, 8);
   }

    @include mq(large) {
        grid-row: 3;
        @include gridCol(1, 4);
    }

    img {
        width: 210px;
        transition: all ease .3s;

        &:hover, &:active {
            transform: scale(1.1)
        }
    }

}

.click {
    @include block($color-highlight-orange, $color-primary-light);
    @include flex-center;
    flex-direction: column;
    padding: 2rem;

    @include mq(medium) {
        flex-direction: unset;
        align-items: center;
        @include gridCol(9, 4);
    }

    @include mq(large) {
        grid-row: 3;
        @include gridCol(5, 2);
    }

    &_content {
        @include flex-column;
    }
    
    svg {
        top: 20%;
        left: 50%;
        width: 1rem;
        height: auto;
        fill: $color-primary-light;
        stroke: $color-primary-light;
        transform: translateX(-50%) rotate(-45deg);
        margin-bottom: 1rem;

        @include mq(medium) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-135deg);
            margin-bottom: unset;
            margin-right: 2rem;
        }
    }
    span {
        font-style: normal;
        text-decoration: underline;
    }
}