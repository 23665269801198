@use '../../styles/abstracts' as *;

.card {
    position: relative;
    @include flex(flex-start, center, column);
    @include block($color-primary-light, $color-primary-dark);
    @include gridCol(1, 12);
    padding: 0 1.5rem;

    
    @include mq(small) {
        padding: 0 2.5rem;
    }

    @include mq(medium) {
        padding: 0 4.5rem;
    }

    @include mq(large) {
        padding: 0 5.5rem;
    }

    img {
        transition: all 300ms ease-in-out;
    }

    &:hover img {
        transform: translateY(-5px);
    }

    @keyframes moveGradient {
         0% {
            background-size: 200% 100%;
            background-position: 0% 50%;
        }
    
        50% {
            background-size: 200% 100%;
            background-position: -100% 50%;
        }
    
        100% {
            background-size: 200% 100%;
            background-position: -170% 50%;
        }
     }

    &:hover .title  {
        animation: moveGradient 2s linear forwards; 
        background: linear-gradient(145deg,$color-primary-dark,$color-primary-dark, #858585);

        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
}

.preview {
    @include grid;
    width: 100%;

    h4 {
        font-weight: 500;
    }

    img {
        width: 100%;
        max-width: 42rem;
        margin-bottom: 1.5rem;
        @include gridCol(auto, 12);
        align-self: center;
        justify-self: center;
        @include mq(large){
            margin-bottom: unset;
            @include gridCol(auto, 6);
        }
    }
}

.blur {
    filter: blur(10px);
}

.content {
    @include gridCol(auto, 12);

    @include mq(large){
        align-self: self-end;
        padding-left: 1.5rem;
        @include gridCol(7, 6);
    }
}

.discipline {
    margin-bottom: 1rem;
    @include stackList;
}

.description {
    margin-bottom: 1.5rem;
} 