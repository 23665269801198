@use '../../../styles/abstracts' as *;

.container {
    background: $color-primary-dark;
    grid-template-rows: 30vh repeat(4, 100vh);
    @include grid;

    @include mq(small) {
        grid-template-rows: 20vh repeat(4, 80vh);
    }

    @include mq(large) {
        grid-template-rows: 35vh repeat(4, 60vh);
    }
}

.title {
    color: $color-primary-light;
    align-self: end;

    @include gridCol(auto, 12);
}