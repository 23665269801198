@use '../abstracts' as *;

:root {
    --bodyColor: "#fcf8f4";
}

body {
    height: 100%;
    background: var(--bodyColor);
    transition: background ease-in 300ms;
    font-family: $font-inter;
    line-height: 1.5;
    position: relative;
    scroll-behavior: smooth;
}


.wrapper {
    padding: 0 .5rem;
    margin: 0 auto;

    @include mq(large) {
        padding: 0 1rem;
    }
}  

a {
    text-decoration: none;
    color: $color-primary-dark;
}

.flex {
    display: flex;
}

.lignt {
    background: $color-primary-light !important;
}

.dark {
    background: $color-primary-dark !important;
}

.white {
    background: $color-secondary-white !important;
}

html.lenis {
    height: auto;
  }
  
  .lenis.lenis-smooth {
    scroll-behavior: auto;
  }
  
  .lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
  }
  
  .lenis.lenis-stopped {
    overflow: hidden;
  }
  
  .lenis.lenis-scrolling iframe {
     pointer-events: none;
  } 