@use '../../styles/abstracts' as *;

.container {
    background: $color-primary-dark;
    @include grid;
    grid-template-rows: 30vh repeat(4, 100vh) repeat(2, 50vh);
   

    @include mq(xsmall) {
        grid-template-rows: 20vh repeat(6, 80vh);
    }

    @include mq(large) {
        grid-template-rows: 40vh repeat(6, 60vh);
    }
}

.title {
    grid-column: span 12;
    align-self: flex-end;
    color: $color-primary-light;
}

.work {
    @include block($color-primary-light, $color-primary-dark);
    grid-column: span 12;
    @include flex-center;

    @include mq(medium) {
        grid-column: span 12;
    }

    h4 {
        font-weight: 500;
    }
}

