@use '../../../../styles/abstracts' as *;


.subtitle {
    position: absolute;
    top: 2rem;
    left: 2rem;
    text-transform: uppercase;

    @include mq(small) {
        top: 3.5rem;
        left: 2rem;
    }

    @include mq(medium) {
        top: 4rem;
        left: 3rem;
    }

    @include mq(large) {
        top: 2.2rem;
        left: 3rem;
    }
}
